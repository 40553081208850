import {styled} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";
import OverlayNavigation from "./navigation/OverlayNavigation";
import OverlayProfile from "./OverlayProfile";
import Logo from "../../assets/img/logo.png";

const Sidebar = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "200px",
    height: "100%",
    backgroundColor: "#FFFFFF",
    paddingLeft: "8px",
    paddingRight: "20px",
  }
})

const Header = styled("div", {
  baseStyle: {
    display: "flex",
    cursor: "pointer",
    marginBottom: "25px",
  }
})

function OverlaySidebar() {

  const navigate = useNavigate();

  return (
    <Sidebar>
      <div>
        <OverlayProfile/>
        <OverlayNavigation/>
      </div>
      <Header onClick={() => navigate(ROUTES.HOME_CHILD)}>
        <img alt="Logo" src={Logo} width={65}/>
      </Header>
    </Sidebar>
  )
}

export default OverlaySidebar;