import {Spinner, styled} from "@chakra-ui/react";
import {CSSProperties, ReactNode} from "react";

const CustomButtonStyle = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    paddingRight: "15px",
    paddingLeft: "15px",
    backgroundColor: "#F5F3ED",
    borderRadius: 25,
    cursor: "pointer",
    userSelect: "none",
    WebkitTouchCallout: "none",
    _hover: {
      backgroundColor: "#EEECE1",
      cursor: "pointer",
    }
  }
})

const ButtonText = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "regular",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
    marginTop: "3px",
    marginLeft: "5px",
    cursor: "pointer",
  }
})

interface ButtonProps {
  children: string,
  onClick?: () => void,
  onHover?: () => void,
  style?: CSSProperties,
  icon?: ReactNode,
  loading?: boolean,
  isDisabled?: boolean,
  textColor?: CSSProperties["color"],
}

function CustomButton({
                        children,
                        onClick,
                        onHover,
                        style,
                        icon,
                        loading,
                        isDisabled,
                        textColor
                      }: ButtonProps) {

  return (
    <CustomButtonStyle
      onClick={onClick}
      onMouseOver={onHover}
      style={style}
    >
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        {loading ? (
          <>
            <Spinner
              thickness='4px'
              speed='1s'
              emptyColor='#F5F3ED'
              color='#2D2D2D'
              size='sm'
            />
          </>
        ) : (
          <>
            {icon && icon}
            <ButtonText style={{color: textColor ? textColor : ""}}>{children}</ButtonText>
          </>
        )}
      </div>
    </CustomButtonStyle>
  )
}

export default CustomButton;