import {DrawerFooter} from "@chakra-ui/react";
import React, {CSSProperties, ReactNode} from "react";
import CustomButton from "../global/button/Button";
import {GiCancel, GiConfirmed} from "react-icons/gi";
import {useTranslation} from "react-i18next";

interface DrawerFooterGenericProps {
  onClose: () => void;
  onSave: () => void;
  labelClose?: string;
  labelSave?: string;
  loading: boolean;
  isDisabled?: boolean;
  onAction?: () => void;
  actionIcon?: ReactNode;
  labelAction?: string;
  styleAction?: CSSProperties;
  colorAction?: CSSProperties["color"];
  loadingAction?: boolean;
}

function DrawerFooterGeneric({
                               onClose,
                               onSave,
                               labelClose,
                               labelSave,
                               loading,
                               isDisabled,
                               onAction,
                               actionIcon,
                               labelAction,
                               styleAction,
                               colorAction,
                               loadingAction
                             }: DrawerFooterGenericProps) {

  const {t} = useTranslation();

  return (
    <>
      <DrawerFooter display={"flex"}
                    justifyContent={onAction ? "space-between" : "end"}>
        {onAction && labelAction && actionIcon && (
          <CustomButton
            icon={actionIcon}
            onClick={onAction}
            loading={loadingAction}
            isDisabled={isDisabled}
            style={styleAction}
            textColor={colorAction ? colorAction : ""}
          >
            {labelAction}
          </CustomButton>
        )}

        <div style={{display: "flex"}}>
          <CustomButton
            icon={<GiCancel/>}
            onClick={onClose}
            style={{marginRight: "1vw", backgroundColor: "#363636", color: "#F5F3ED"}}
            textColor={"#F5F3ED"}
            isDisabled={isDisabled}
          >
            {labelClose ? labelClose : t("labels.cancel")}
          </CustomButton>
          <CustomButton
            icon={<GiConfirmed/>}
            onClick={onSave}
            loading={loading}
            isDisabled={isDisabled}
          >
            {labelSave ? labelSave : t("labels.save")}
          </CustomButton>
        </div>
      </DrawerFooter>
    </>
  )
}

export default DrawerFooterGeneric;