import {NavigationElement} from "../../../types/navigation";
import {ROUTES} from "../../../utils/consts/router";
import {RxDashboard} from "react-icons/rx";
import {styled} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {HiOutlineUserGroup} from "react-icons/hi";
import {IoBusinessOutline} from "react-icons/io5";

const Navigation = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginTop: "40px",
  }
})

const Element = styled("div", {
  baseStyle: {
    height: "40px",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    fontFamily: "Mabry Pro",
    fontWeight: "normal",
    fontSize: "15px",
    cursor: "pointer",
    marginLeft: "10px",
  }
})

const Icon = styled("div", {
  baseStyle: {
    marginRight: 2,
  }
})

const navigationElements: NavigationElement[] = [
  {
    title: "Dashboard",
    icon: <RxDashboard style={{width: 16, height: 16}}/>,
    path: ROUTES.HOME_CHILD,
  },
  {
    title: "Clients",
    icon: <HiOutlineUserGroup style={{width: 16, height: 16}}/>,
    path: ROUTES.CUSTOMERS_CHILD,
  },
  {
    title: "Prospections",
    icon: <IoBusinessOutline style={{width: 16, height: 16}}/>,
    path: ROUTES.PROSPECTIONS_CHILD,
  },
]

function OverlayNavigation() {

  const navigate = useNavigate();

  return (
    <Navigation>
      {navigationElements.map((element, index) => {

        const current = window.location.pathname === element.path || element.path_child?.includes(window.location.pathname);

        return (
          <Element key={index} onClick={() => navigate(element.path)}>
            <Icon
              style={{
                color: current ? "#2B2A24" : "#54524A",
              }}
            >
              {element.icon}
            </Icon>
            <div style={{
              color: current ? "#2B2A24" : "#54524A",
              textUnderlineOffset: "3px",
              textUnderlinePosition: "under",
              textDecoration: "underline",
              textDecorationColor: current ? "#54524A" : "transparent",
              textDecorationThickness: "0.1rem",
            }}
            >
              {element.title}
            </div>
          </Element>
        )
      })}
    </Navigation>
  )
}

export default OverlayNavigation;