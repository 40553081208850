import {useParams} from "react-router-dom";
import {styled} from "@chakra-ui/react";
import PageTemplate from "../../components/global/views/PageTemplate";
import Typography from "../../components/global/Typography";
import {useTranslation} from "react-i18next";
import Enterprise from "../../models/enterprise";
import {useEffect, useState} from "react";
import EnterpriseServices from "../../services/EnterpriseServices";
import Interlocutor from "../../models/interlocutor";
import InterlocutorServices from "../../services/InterlocutorServices";
import InterlocutorCard from "../../components/global/card/InterlocutorCard";
import CustomButton from "../../components/global/button/Button";
import {HiPlus} from "react-icons/hi";

const PageContentHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  }
})

function EnterpriseLanding() {

  const {t} = useTranslation();

  const {id} = useParams();
  const [enterprise, setEnterprise] = useState<Enterprise>(new Enterprise());
  const [interlocutors, setInterlocutors] = useState<Interlocutor[]>([]);

  useEffect(() => {
    EnterpriseServices.getEnterpriseByID(id as string)
      .then((response) => setEnterprise(response))
      .catch(() => setEnterprise(new Enterprise()))

    InterlocutorServices.getAllInterlocutors({'enterprise__id': id as string})
      .then((response) => setInterlocutors([...response].sort((a, b) => a.name.localeCompare(b.name))))
      .catch(() => setInterlocutors([]))
  }, []);

  return (
    <PageTemplate style={{minWidth: "500px"}}>
      <PageContentHeader>
        <Typography variant="title">{enterprise.name}</Typography>
      </PageContentHeader>

      <div style={{display: "flex", justifyContent: "space-between", width: "70%"}}>

        <div>Section 1</div>
        <div>
          <CustomButton icon={<HiPlus/>} onClick={() => console.log("add interlocutor")}>
            {t("pages.enterprise.add_interlocutor")}
          </CustomButton>
          {interlocutors.map((interlocutor) => (
            <InterlocutorCard interlocutor={interlocutor} interlocutors={interlocutors}/>
          ))}
        </div>

      </div>
    </PageTemplate>
  )
}

export default EnterpriseLanding;