import {useState, useContext, ReactNode, Dispatch, SetStateAction, createContext, useEffect} from 'react';
import {useToast} from "@chakra-ui/react";
import Api from "../services/Api";
import User from "../models/user";
import UserServices from "../services/UserServices";

type TAuthContext = {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  isLogged: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  handleLogin: (email: string, password: string) => void;
  handleLogout: () => void;
  user: User | undefined;
};

const AuthContext = createContext<TAuthContext>({} as TAuthContext);
const useAuthContext = () => useContext(AuthContext);

function AuthProvider({children}: { children: ReactNode }) {

  const toast = useToast();
  const [token, setToken] = useState<string>(localStorage.getItem("token") || "");
  const [isLogged, setIsLogged] = useState<boolean>(false);

  const [user, setUser] = useState<User>();

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    if (!isLogged && token !== "") {
      UserServices.getUser().then((response) => {
        setUser(response);
        setIsLogged(true);
      }).catch((error) => {
        console.log(error);
        setIsLogged(false);
        return;
      })
    }
  }, [token]);

  const handleLogin = (email: string, password: string) => {

    if (email === "" || password === "") {
      toast({
        title: "Error",
        description: "Email or password is empty",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
      return;
    }

    Api.login({email: email, password: password})
      .then((response) => {
        setToken(response.token);
        setIsLogged(true);
      })
      .catch((error) => {
        setIsLogged(false);
        console.log(error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      })
  }

  const handleLogout = () => {
    setToken("");
    setIsLogged(false);
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        isLogged,
        setIsLogged,
        handleLogin,
        handleLogout,
        user
      }}>
      {children}
    </AuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export {AuthProvider, useAuthContext};
export type {TAuthContext};
