import PageTemplate from "../../components/global/views/PageTemplate";
import {Avatar, Stack, styled} from "@chakra-ui/react";
import CustomButton from "../../components/global/button/Button";
import Typography from "../../components/global/Typography";
import {useTranslation} from "react-i18next";
import {HiPlus} from "react-icons/hi";
import SearchBar from "../../components/global/input/SearchBar/SearchBar";
import {FaUserAlt} from "react-icons/fa";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";
import {IoArrowForwardOutline} from "react-icons/io5";

const PageContentHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  }
})

const PageContentBody = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
    marginTop: "20px",
  }
})

function Prospections() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [prospections, setProspections] = useState<[]>([]);

  return (
    <PageTemplate style={{minWidth: "500px"}}>
      <PageContentHeader>
        <Typography variant="title">{t("pages.prospections.title")}</Typography>
        <CustomButton icon={<HiPlus/>} onClick={() => navigate(ROUTES.PROSPECTIONS_CREATE)}>
          {t("pages.prospections.add")}
        </CustomButton>
      </PageContentHeader>

      <SearchBar
        placeholder={t("pages.prospections.list.search") as string}
        icon={<FaUserAlt color="#918D86"/>}
        styleSearchBar={{width: "60%", minWidth: "300px", height: "40px", marginTop: "20px", marginBottom: "2"}}
      />

      <Stack direction={"column"} w={"60%"} marginTop={"30px"}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="subtitle">{t("pages.prospections.top")}</Typography>

          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}
                 style={{cursor: "pointer"}} onClick={() => navigate(ROUTES.PROSPECTIONS_TOP)}
          >
            <Typography variant={"body"}>{t("pages.prospections.top_access")}</Typography>
            <IoArrowForwardOutline size={"18px"} color={"#2D2D2D"} style={{cursor: "pointer"}}/>
          </Stack>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack w={"calc(100% / 3.2)"} border={"1px solid #2D2D2D"} h={"180px"} borderRadius={"8px"}
                 alignItems={"center"} justifyContent={"center"}
          >
            <Avatar size={"lg"} name={"Top1"}/>
            <Typography variant={"body"}>Clément LOEUILLET</Typography>
            <Typography variant={"body"}>Top1</Typography>
          </Stack>

          <Stack w={"calc(100% / 3.2)"} border={"1px solid black"} h={"180px"} borderRadius={"8px"}
                 alignItems={"center"} justifyContent={"center"}
          >
            <Avatar size={"lg"} name={"Top2"}/>
            <Typography variant={"body"}>Clément LOEUILLET</Typography>
            <Typography variant={"body"}>Top2</Typography>
          </Stack>

          <Stack w={"calc(100% / 3.2)"} border={"1px solid black"} h={"180px"} borderRadius={"8px"}
                 alignItems={"center"} justifyContent={"center"} position={"relative"}
          >
            <Avatar size={"lg"} name={"Top3"}/>
            <Typography variant={"body"}>Clément LOEUILLET</Typography>
            <Typography variant={"body"}>Top3</Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={"column"} w={"60%"} marginTop={"30px"}>
        <Typography variant="subtitle">{t("pages.prospections.list.title")}</Typography>

        <PageContentBody>
          {loading ? (
            <Typography variant="body">{t("pages.enterprise.list.loading")}</Typography>
          ) : (
            <div style={{width: "100%"}}>
              {prospections.map((prospection) => <div key={JSON.stringify(prospection)}>{prospection}</div>)}
            </div>
          )}
        </PageContentBody>
      </Stack>

    </PageTemplate>
  );
}

export default Prospections;