import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  styled,
  useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import {BiRename} from "react-icons/bi";
import InputIcon from "../../global/input/InputIcon";
import DrawerFooterGeneric from "../DrawerFooterGeneric";
import Enterprise from "../../../models/enterprise";
import EnterpriseServices from "../../../services/EnterpriseServices";

const CustomDrawerHeader = styled(DrawerHeader, {
  baseStyle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "light",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
  }
})

const CustomDrawerBody = styled(DrawerBody, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    width: "100%",
  }
})

interface EnterpriseCreateDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  enterprises: Enterprise[];
  setEnterprises: (enterprises: Enterprise[]) => void;
}

function EnterpriseCreateDrawer({
                            isOpen,
                            onClose,
                            enterprises,
                            setEnterprises,
                          }: EnterpriseCreateDrawerProps) {

  const [newEnterprise, setNewEnterprise] = useState<Enterprise>(new Enterprise());
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const {t} = useTranslation();

  const handleCreateEnterprise = () => {
    setLoading(true);
    EnterpriseServices.create(newEnterprise)
      .then((enterprise) => {
        setEnterprises([...enterprises, enterprise].sort((a, b) => a.name.localeCompare(b.name)))
        setLoading(false)
        toast({
          title: t("pages.enterprises.create.success"),
          status: "success",
          duration: 4500,
          isClosable: true,
        })
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: t("pages.enterprises.create.error"),
          description: t(`errors.${error.message}`),
          status: "error",
          duration: 4500,
          isClosable: true,
        })
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <CustomDrawerHeader>
          {t("pages.enterprise.create.title")}
        </CustomDrawerHeader>

        <CustomDrawerBody>

          <div style={{height: "30px"}}/>

          <InputIcon
            icon={<BiRename color="#918D86"/>}
            placeholder={t("labels.lastname") as string}
            value={newEnterprise.name}
            onChange={(e) => setNewEnterprise({...newEnterprise, name: e.target.value})}
            type="text"
          />
          <InputIcon
            icon={<AiOutlineMail color="#918D86"/>}
            placeholder={t("labels.mail") as string}
            value={newEnterprise.email}
            onChange={(e) => setNewEnterprise({...newEnterprise, email: e.target.value})}
            type="email"
          />

          <InputIcon
            icon={<AiOutlinePhone color="#918D86"/>}
            placeholder={t("labels.phone_number") as string}
            value={newEnterprise.phone}
            onChange={(e) => setNewEnterprise({...newEnterprise, phone: e.target.value})}
            type="tel"
          />

        </CustomDrawerBody>
        <DrawerFooterGeneric onClose={onClose}
                             onSave={() => handleCreateEnterprise()} loading={loading}/>
      </DrawerContent>
    </Drawer>
  )
}

export default EnterpriseCreateDrawer;