
function Profile() {

  return (
    <div>
      <h1>Profile</h1>
    </div>
  );
}

export default Profile;