import Api from "./Api";
import Enterprise from "../models/enterprise";

interface EnterpriseListParams {
  name__icontains?: string;
  name__in?: string[];
}

export default class EnterpriseServices {

  static getAllEnterprises = (params?: EnterpriseListParams) => {
    return Api.get(`enterprise/all/`, params);
  };

  static create = (enterprise: Enterprise
  ) => {
    return Api.post(`enterprise/`, {
      name: enterprise.name,
      email: enterprise.email,
      phone: enterprise.phone,
      address: enterprise.address,
      city: enterprise.city,
      zip_code: enterprise.zip_code,
      country: enterprise.country,
    });
  };

  static getEnterpriseByID = (enterpriseID: string) => {
    return Api.get(`enterprise/${enterpriseID}/`);
  }

  static update = (
    enterprise: Enterprise,
    enterpriseID: string,
  ) => {
    return Api.put(`enterprise/${enterpriseID}/`, enterprise);
  }

}
