import {ChakraProvider} from "@chakra-ui/react";
import {RouterProvider} from "react-router-dom";
import {dashboardRouter} from "./router/Router";
import Error404 from "./views/errors/Error404";
import {AuthProvider, useAuthContext} from "./context/AuthContext";
import {useEffect, useState} from "react";
import Login from "./views/Login";

function RootElement() {
  const { isLogged } = useAuthContext();

  const [element, setElement] = useState<JSX.Element>(<Login />);

  useEffect(() => {
    if (isLogged) {
      setElement(
        <RouterProvider router={dashboardRouter} fallbackElement={<Error404/>}/>
      );
    } else {
      setElement(<Login />);
    }
  }, [isLogged]);

  return (
    <>
      {element}
    </>
  )
}

function App() {

  return (
    <AuthProvider>
      <ChakraProvider>
        <RootElement />
      </ChakraProvider>
    </AuthProvider>
  )
}

export default App
