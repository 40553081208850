import {ReactNode} from "react";
import {styled} from "@chakra-ui/react";

const TypographyNeutral = styled("div", {
  baseStyle: {
    color: "#000000",
    fontFamily: "Mabry Pro",
    fontWeight: "normal",
    fontSize: "15px",
    userSelect: "none",
    WebkitTouchCallout: "none",
  }
})

const TypographyTitle = styled("div", {
  baseStyle: {
    color: "#000000",
    fontFamily: "Lora",
    fontWeight: "bold",
    fontSize: "20px",
    userSelect: "none",
    WebkitTouchCallout: "none",
  }
})

const TypographyBody = styled("div", {
  baseStyle: {
    color: "#000000",
    fontFamily: "Mabry Pro",
    fontWeight: "normal",
    fontSize: "15px",
    userSelect: "none",
    WebkitTouchCallout: "none",
  }
})

const TypographySubtitle = styled("div", {
  baseStyle: {
    color: "#000000",
    fontFamily: "Mabry Pro",
    fontWeight: "normal",
    fontSize: "18px",
    userSelect: "none",
    WebkitTouchCallout: "none",
  }
})

interface TypographyProps {
  variant: "neutral" | "title" | "body" | "subtitle",
  children: string | number | ReactNode,
  style?: object,
}

function Typography({
  variant,
  children,
  style,
}: TypographyProps) {

  let encapsulate;

  switch (variant) {
    case "neutral":
      encapsulate = <TypographyNeutral style={style}>{children}</TypographyNeutral>
      break;
    case "title":
      encapsulate = <TypographyTitle style={style}>{children}</TypographyTitle>
      break;
    case "body":
      encapsulate = <TypographyBody style={style}>{children}</TypographyBody>
      break;
    case "subtitle":
      encapsulate = <TypographySubtitle style={style}>{children}</TypographySubtitle>
      break;
    default:
      encapsulate = <TypographyNeutral style={style}>{children}</TypographyNeutral>
      break;
  }

  return (
    <>
      {encapsulate}
    </>
  )
}

export default Typography;