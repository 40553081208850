import {Input, InputGroup, InputLeftElement, styled} from "@chakra-ui/react";

const InputLabel = styled("label", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2D2D2D",
    fontFamily: "Mabry Pro",
    marginTop: "7px",
    marginLeft: "5px",
  }
})


interface InputIconProps {
  icon: any;
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  type?: string;
  error?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isInvalid?: boolean;
  style?: any;
  width?: string;
}

function InputIcon({
                     icon,
                     placeholder,
                     value,
                     onChange,
                     onBlur,
                     type,
                     error,
                     errorMessage,
                     isRequired,
                     isDisabled,
                     isReadOnly,
                     isInvalid,
                     style,
                     width = "100%",
                   }: InputIconProps) {

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width : width,
      minWidth: "200px",
      //TODO: check the min and max width after for full responsive
    }}>
      <InputLabel>{placeholder}</InputLabel>
      <InputGroup placeholder={placeholder} marginTop={"2px"} marginBottom={"3px"}>
        <InputLeftElement pointerEvents='none'>
          {icon}
        </InputLeftElement>
        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          color="#918D86"
          borderColor={"#918D86"}
          fontFamily={"Mabry Pro"}
          _hover={{
            borderColor: "#918D86",
            backgroundColor: "#F5F3ED"
          }}
          _placeholder={{
            color: "#918D86"
          }}
          isInvalid={isInvalid}
          isRequired={isRequired}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          errorBorderColor={"#b01818"}
          style={style}
        />
      </InputGroup>
    </div>
  )
}

export default InputIcon;