import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en.json";
import translationFR from "./fr.json";

export enum Languages {
  EN = "EN",
  FR = "FR",
}

// the translations
const resources = {
  [Languages.EN]: {
    translation: translationEN,
  },
  [Languages.FR]: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: Object.values(Languages),
    resources,
    lng: Languages.FR,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
