export default class Interlocutor {
  id: string;
  enterprise: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;

  constructor({
                id = "",
                enterprise = "",
                first_name = "",
                last_name = "",
                phone = "",
                email = "",
              } = {}) {
    this.id = id;
    this.enterprise = enterprise;
    this.first_name = first_name;
    this.last_name = last_name;
    this.phone = phone;
    this.email = email;
  }
}