import {styled} from "@chakra-ui/react";
import {CSSProperties} from "react";

const PageContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "60px",
    width: "100%",
  }
})

interface PageTemplateProps {
  children: any;
  style?: CSSProperties;
}

function PageTemplate({
                        children,
                        style
                      }: PageTemplateProps) {
  return (
    <PageContent style={style ? style : {}}>
      {children}
    </PageContent>
  );
}

export default PageTemplate;