export const ROUTES = {
  HOME: '/*',
  HOME_CHILD: '/',

  CUSTOMERS: '/enterprises/*',
  CUSTOMERS_CHILD: '/enterprises',
  CUSTOMERS_DETAIL: '/enterprises/:id/*',
  CUSTOMERS_DETAIL_CHILD: '/enterprises/:id',
  CUSTOMERS_CREATE: '/enterprises/create',

  PROSPECTIONS: '/prospections/*',
  PROSPECTIONS_CHILD: '/prospections',
  PROSPECTIONS_DETAIL: '/prospections/:id/*',
  PROSPECTIONS_DETAIL_CHILD: '/prospections/:id',
  PROSPECTIONS_CREATE: '/prospections/new',
  PROSPECTIONS_TOP: '/prospections/top',

  PROFILE: '/profile',
  ERROR_404: "*/*",
  LOGIN: '/login',
  DEFAULT_SITE: "https://juniortaker.com"
}
