import {Route, Routes} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";
import HomeLanding from "./HomeLanding";
import Profile from "../profile/Profile";
import Enterprises from "../enterprises/Enterprises";
import Prospections from "../prospections/Prospections";
import EnterpriseLanding from "../enterprises/EnterpriseLanding";
import PageTemplateMain from "../PageTemplate";
import CreateProspection from "../prospections/create/CreateProspection";
import TopProspection from "../prospections/top/TopProspection";

function Home() {

  return(
    <PageTemplateMain>
      <Routes>
        <Route path={ROUTES.HOME_CHILD} element={<HomeLanding/>}/>
        <Route path={ROUTES.CUSTOMERS_CHILD} element={<Enterprises/>}/>
        <Route path={ROUTES.CUSTOMERS_DETAIL_CHILD} element={<EnterpriseLanding/>}/>
        <Route path={ROUTES.PROSPECTIONS_CHILD} element={<Prospections/>}/>
        <Route path={ROUTES.PROSPECTIONS_CREATE} element={<CreateProspection/>}/>
        <Route path={ROUTES.PROSPECTIONS_TOP} element={<TopProspection/>}/>
        <Route path={ROUTES.PROFILE} element={<Profile/>}/>
      </Routes>
    </PageTemplateMain>
  )
}

export default Home;