import {createBrowserRouter} from "react-router-dom";
import {ROUTES} from "../utils/consts/router";
import Error404 from "../views/errors/Error404";
import Home from "../views/home/Home";
import Login from "../views/Login";

function DashboardRouter() {

  return createBrowserRouter([
    {
      path: ROUTES.HOME,
      element: <Home/>,
      errorElement: <Error404/>,
    },
    {
      path: ROUTES.LOGIN,
      element: <Login/>,
      errorElement: <Error404/>,
    }
  ])
}

export const dashboardRouter = DashboardRouter();