import Typography from "../../../components/global/Typography";
import CustomButton from "../../../components/global/button/Button";
import {HiPlus} from "react-icons/hi";
import {ROUTES} from "../../../utils/consts/router";
import SearchBar from "../../../components/global/input/SearchBar/SearchBar";
import {FaUserAlt} from "react-icons/fa";
import {Avatar, Stack, styled} from "@chakra-ui/react";
import {IoArrowBackOutline, IoArrowForwardOutline} from "react-icons/io5";
import PageTemplate from "../../../components/global/views/PageTemplate";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const PageContentHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  }
})

const PageContentBody = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
    marginTop: "20px",
  }
})

function TopProspection() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <PageTemplate style={{minWidth: "500px", position: "relative"}}>

      <Stack direction={"row"} position={"absolute"} top={"5"} left={"5"} justifyContent={"center"}
             alignItems={"center"} style={{cursor: "pointer"}} onClick={() => navigate(-1)}
      >
        <IoArrowBackOutline size={"18px"} color={"#2D2D2D"} style={{cursor: "pointer"}}/>
        <Typography variant={"body"}>{t("labels.back")}</Typography>
      </Stack>

      <PageContentHeader>
        <Typography variant="title">{t("pages.prospections.top")}</Typography>
      </PageContentHeader>

      <SearchBar
        placeholder={t("pages.prospections.list.search") as string}
        icon={<FaUserAlt color="#918D86"/>}
        styleSearchBar={{width: "60%", minWidth: "300px", height: "40px", marginTop: "20px", marginBottom: "2"}}
      />

    </PageTemplate>
  )
}

export default TopProspection;