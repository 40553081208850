import PageTemplate from "../../components/global/views/PageTemplate";
import {styled, useDisclosure} from "@chakra-ui/react";
import CustomButton from "../../components/global/button/Button";
import Typography from "../../components/global/Typography";
import {useTranslation} from "react-i18next";
import {HiPlus} from "react-icons/hi";
import SearchBar from "../../components/global/input/SearchBar/SearchBar";
import {FaUserAlt} from "react-icons/fa";
import {useEffect, useState} from "react";
import Enterprise from "../../models/enterprise";
import CustomerCard from "../../components/global/card/CustomerCard";
import EnterpriseCreateDrawer from "../../components/Enterprise/EnterpriseCreate/EnterpriseCreateDrawer";
import EnterpriseServices from "../../services/EnterpriseServices";

const PageContentHeader = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  }
})

const PageContentBody = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
    marginTop: "20px",
  }
})

function Enterprises() {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [enterprises, setEnterprises] = useState<Enterprise[]>([]);

  useEffect(() => {
    setLoading(true);
    EnterpriseServices.getAllEnterprises()
      .then((response) => setEnterprises([...response].sort((a, b) => a.name.localeCompare(b.name))))
      .catch(() => setEnterprises([]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <PageTemplate style={{minWidth:"500px"}}>
      <PageContentHeader>
        <Typography variant="title">{t("pages.enterprise.title")}</Typography>
        <CustomButton icon={<HiPlus/>} onClick={() => onOpen()}>
          {t("pages.enterprise.add")}
        </CustomButton>
      </PageContentHeader>

      <SearchBar
        placeholder={t("pages.enterprise.list.search") as string}
        icon={<FaUserAlt color="#918D86"/>}
        styleSearchBar={{width: "60%", minWidth: "300px", height: "40px", marginTop: "20px", marginBottom: "2"}}
      />

      <PageContentBody>
        {loading ? (
          <Typography variant="body">{t("pages.enterprise.list.loading")}</Typography>
        ) : (
          <div style={{width: "100%"}}>
            {enterprises.map((enterprise) => <CustomerCard enterprise={enterprise} key={JSON.stringify(enterprise)}
                                           setEnterprises={setEnterprises} enterprises={enterprises}/>)}
          </div>
        )}
      </PageContentBody>

      <EnterpriseCreateDrawer isOpen={isOpen} onClose={onClose} enterprises={enterprises} setEnterprises={setEnterprises}/>

    </PageTemplate>
  );
}

export default Enterprises;