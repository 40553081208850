import {Link} from "react-router-dom";
import {styled} from "@chakra-ui/react";

const PageContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }
});

function Error404 () {
  return (
    <PageContent>
      <h1>404</h1>
      <p>Page not found</p>
      <p>Go back to <Link to="/">home</Link></p>
    </PageContent>
  )
}

export default Error404;