import {useEffect, useState} from "react";
import Interlocutor from "../../../models/interlocutor";
import {Stack} from "@chakra-ui/react";
import Typography from "../../global/Typography";
import SearchBar from "../../global/input/SearchBar/SearchBar";
import {FaUserAlt} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {useDebounce} from "usehooks-ts";
import {MdCancel} from "react-icons/md";
import InterlocutorServices from "../../../services/InterlocutorServices";
import Button from "../../global/button/Button";

interface ProspectionCreateInterlocutorProps {
  interlocutor: Interlocutor | undefined;
  setInterlocutor: (interlocutor: Interlocutor) => void;
  removeInterlocutor: () => void;
}

function ProspectionCreateInterlocutor({interlocutor, setInterlocutor, removeInterlocutor}: ProspectionCreateInterlocutorProps) {

  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const [interlocutorKey, setInterlocutorKey] = useState<string>("");
  const [interlocutorResearched, setInterlocutorResearched] = useState<Interlocutor[]>([]);

  const debouncedEnterpriseKey = useDebounce(interlocutorKey, 500);

  useEffect(() => {
    if (debouncedEnterpriseKey) {
      setLoading(true);
      InterlocutorServices.getAllInterlocutors({email__icontains: debouncedEnterpriseKey})
        .then((res) => setInterlocutorResearched(res))
        .finally(() => setLoading(false));
    } else {
      setInterlocutorResearched([]);
    }
  }, [debouncedEnterpriseKey]);

  return (
    <>
      {interlocutor ? (
        <Stack direction={"row"} w={"60%"} marginTop={"30px"} borderColor={"#918D86"} borderWidth={"1px"}
               borderRadius={"5px"} padding={"10px"} justifyContent={"space-between"}
               alignItems={"center"}
        >
          <Typography variant={"subtitle"}>{interlocutor.email}</Typography>
          <MdCancel
            color={"#918D86"}
            size={"18px"}
            style={{cursor: "pointer"}}
            onClick={() => {
              removeInterlocutor()
              setInterlocutorKey("")
              setInterlocutorResearched([])
            }}
          />
        </Stack>
      ) : (
        <>
          <SearchBar
            text={interlocutorKey}
            setText={setInterlocutorKey}
            placeholder={t("pages.prospections_create.search_interlocutor") as string}
            icon={<FaUserAlt color="#918D86"/>}
            styleSearchBar={{width: "60%", minWidth: "300px", height: "40px", marginTop: "20px", marginBottom: "2"}}
          />
          {loading ? (
            <Typography variant={"subtitle"}>{t("pages.prospections_create.loading")}</Typography>
          ) : (
            <Stack direction={"column"} w={"60%"} marginTop={"30px"}>
              {interlocutorResearched.length == 0 && debouncedEnterpriseKey.length > 0 ? (
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <Typography variant={"subtitle"}>{t("pages.prospections_create.no_interlocutor")}</Typography>
                  <Button
                    onClick={() => {
                      setInterlocutor(new Interlocutor({email: interlocutorKey}))
                      setInterlocutorKey("")
                      setInterlocutorResearched([])
                    }}
                  >
                    {t("pages.prospections_create.create_interlocutor")}
                  </Button>
                </Stack>
              ) : (
                <>
                  {interlocutorResearched.map((interlocutor) => (
                    <Stack direction={"row"} justifyContent={"space-between"} key={interlocutor.id}
                           style={{cursor: "pointer"}}
                           onClick={() => setInterlocutor(interlocutor)}
                    >
                      <Typography variant={"subtitle"}>{interlocutor.email}</Typography>
                      <Typography variant={"subtitle"}>{interlocutor.id}</Typography>
                    </Stack>
                  ))}
                </>
              )}
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default ProspectionCreateInterlocutor;
