export default class Enterprise {

  id: string;

  company: string;

  name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  zip_code: string;
  country: string;

  owner: string;
  creator: string;

  constructor({
                id = "",
                company = "",
                name = "",
                phone = "",
                email = "",
                address = "",
                city = "",
                zip_code = "",
                country = "",
                owner = "",
                creator = "",
              } = {}) {
    this.id = id;
    this.company = company;
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.city = city;
    this.zip_code = zip_code;
    this.country = country;
    this.owner = owner;
    this.creator = creator;
  }
}