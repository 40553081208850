import Api from "./Api";
import Interlocutor from "../models/interlocutor";

interface InterlocutorListParams {
  email__icontains?: string;
  email__in?: string[];
  first_name__icontains?: string;
  first_name__in?: string[];
  last_name__icontains?: string;
  last_name__in?: string[];
}

export default class InterlocutorServices {

  static getAllInterlocutors = (params: InterlocutorListParams) => {
    return Api.get(`interlocutor/all/`, params);
  };

  static create = (interlocutor: Interlocutor
  ) => {
    return Api.post(`interlocutor/`, {
      first_name: interlocutor.first_name,
      last_name: interlocutor.last_name,
      email: interlocutor.email,
      phone: interlocutor.phone,
      enterprise: interlocutor.enterprise,
    });
  };

  static getInterlocutorByID = (enterpriseID: string) => {
    return Api.get(`interlocutor/${enterpriseID}/`);
  }

  static update = (
    interlocutor: Interlocutor,
    interlocutorID: string,
  ) => {
    return Api.put(`interlocutor/${interlocutorID}/`, interlocutor);
  }

}
