
interface ImageProps {
  src: any;
  alt: string;
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
}

function Image({
  src,
  alt,
  width,
  height,
  borderRadius,
}: ImageProps) {

  return <img alt={alt} src={src} width={width} height={height} style={{
    borderRadius: borderRadius ? borderRadius : 0,
  }}/>
}

export default Image;