import PageTemplate from "../../../components/global/views/PageTemplate";
import Typography from "../../../components/global/Typography";
import {useTranslation} from "react-i18next";
import {Stack} from "@chakra-ui/react";
import {IoArrowBackOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import Enterprise from "../../../models/enterprise";
import {useState} from "react";
import ProspectionCreateEnterprise from "../../../components/prospections/create/ProspectionCreateEnterprise";
import Interlocutor from "../../../models/interlocutor";
import ProspectionCreateInterlocutor from "../../../components/prospections/create/ProspectionCreateInterlocutor";

function CreateProspection() {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const [enterprise, setEnterprise] = useState<Enterprise>();
  const [interlocutor, setInterlocutor] = useState<Interlocutor>();

  const removeEnterprise = () => {
    setEnterprise(undefined);
  }

  const removeInterlocutor = () => {
    setInterlocutor(undefined);
  }

  return (
    <PageTemplate style={{minWidth: "500px", position: "relative"}}>
      <Stack direction={"row"} position={"absolute"} top={"5"} left={"5"} justifyContent={"center"}
             alignItems={"center"} style={{cursor: "pointer"}} onClick={() => navigate(-1)}
      >
        <IoArrowBackOutline size={"18px"} color={"#2D2D2D"} style={{cursor: "pointer"}}/>
        <Typography variant={"body"}>{t("labels.back")}</Typography>
      </Stack>

      <Stack direction={"column"} w={"60%"}>
        <Typography variant={"title"}>{t("pages.prospections_create.title")}</Typography>
      </Stack>

      <Stack direction={"column"} w={"60%"} marginTop={"30px"}>
        <Typography variant={"subtitle"}>{t("pages.prospections_create.select_enterprise")}</Typography>
      </Stack>

      <ProspectionCreateEnterprise enterprise={enterprise} setEnterprise={setEnterprise} removeEnterprise={removeEnterprise}/>

      {enterprise && (
        <>
          <Stack direction={"column"} w={"60%"} marginTop={"30px"}>
            <Typography variant={"subtitle"}>{t("pages.prospections_create.select_interlocutor")}</Typography>
          </Stack>

          <ProspectionCreateInterlocutor interlocutor={interlocutor} setInterlocutor={setInterlocutor} removeInterlocutor={removeInterlocutor}/>
        </>
      )}

    </PageTemplate>
  )
}

export default CreateProspection;
