import {useEffect, useState} from "react";
import Enterprise from "../../../models/enterprise";
import {Stack} from "@chakra-ui/react";
import Typography from "../../global/Typography";
import SearchBar from "../../global/input/SearchBar/SearchBar";
import {FaUserAlt} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {useDebounce} from "usehooks-ts";
import EnterpriseServices from "../../../services/EnterpriseServices";
import {MdCancel} from "react-icons/md";

interface ProspectionCreateEnterpriseProps {
  enterprise: Enterprise | undefined;
  setEnterprise: (enterprise: Enterprise) => void;
  removeEnterprise: () => void;
}

function ProspectionCreateEnterprise({enterprise, setEnterprise, removeEnterprise}: ProspectionCreateEnterpriseProps) {

  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const [enterpriseKey, setEnterpriseKey] = useState<string>("");
  const [enterpriseResearched, setEnterpriseResearched] = useState<Enterprise[]>([]);

  const debouncedEnterpriseKey = useDebounce(enterpriseKey, 500);

  useEffect(() => {
    if (debouncedEnterpriseKey) {
      setLoading(true);
      EnterpriseServices.getAllEnterprises({name__icontains: debouncedEnterpriseKey})
        .then((res) => setEnterpriseResearched(res))
        .finally(() => setLoading(false));
    } else {
      setEnterpriseResearched([]);
    }
  }, [debouncedEnterpriseKey]);

  return (
    <>
      {enterprise ? (
        <Stack direction={"row"} w={"60%"} marginTop={"30px"} borderColor={"#918D86"} borderWidth={"1px"}
               borderRadius={"5px"} padding={"10px"} justifyContent={"space-between"}
               alignItems={"center"}
        >
          <Typography variant={"subtitle"}>{enterprise.name}</Typography>
          <MdCancel
            color={"#918D86"}
            size={"18px"}
            style={{cursor: "pointer"}}
            onClick={() => {
              removeEnterprise()
              setEnterpriseKey("")
              setEnterpriseResearched([])
            }}
          />
        </Stack>
      ) : (
        <>
          <SearchBar
            text={enterpriseKey}
            setText={setEnterpriseKey}
            placeholder={t("pages.prospections_create.search_enterprise") as string}
            icon={<FaUserAlt color="#918D86"/>}
            styleSearchBar={{width: "60%", minWidth: "300px", height: "40px", marginTop: "20px", marginBottom: "2"}}
          />
          {loading ? (
            <Typography variant={"subtitle"}>{t("pages.prospections_create.loading")}</Typography>
          ) : (
            <Stack direction={"column"} w={"60%"} marginTop={"30px"}>
              {enterpriseResearched.length == 0 && debouncedEnterpriseKey.length > 0 ? (
                <Typography variant={"subtitle"}>{t("pages.prospections_create.no_enterprise")}</Typography>
              ) : (
                <>
                  {enterpriseResearched.map((enterprise) => (
                    <Stack direction={"row"} justifyContent={"space-between"} key={enterprise.id}
                           style={{cursor: "pointer"}}
                           onClick={() => setEnterprise(enterprise)}
                    >
                      <Typography variant={"subtitle"}>{enterprise.name}</Typography>
                      <Typography variant={"subtitle"}>{enterprise.id}</Typography>
                    </Stack>
                  ))}
                </>
              )}
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default ProspectionCreateEnterprise;
