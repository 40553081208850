import User from "../models/user";
import Api from "./Api";

export default class UserServices {
  static getUser = () => {
    return Api.get(`user/`);
  };

  static getAllUsers = () => {
    return Api.get(`user/all/`);
  };

  static create = (user: User
  ) => {
    return Api.post(`user/`, {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      date_of_birth: user.date_of_birth,
      phone: user.phone,
    });
  };

  static getUserByID = (userID: string) => {
    return Api.get(`user/${userID}/`);
  }

  static update = (
    user: User,
    userID: string,
  ) => {
    return Api.put(`user/${userID}/`, user);
  }

}
