import {Input, InputGroup, InputLeftElement, styled} from "@chakra-ui/react";
import {FaUserAlt} from "react-icons/fa";
import {CSSProperties} from "react";

const PageSearchBar = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
})

interface SearchBarProps {
  styleSearchBar?: CSSProperties;
  placeholder?: string;
  icon?: any;
  text?: string;
  setText?: (text: string) => void;
}

function SearchBar({
                     styleSearchBar,
                     placeholder = "Search",
                     icon = <FaUserAlt color="#918D86"/>,
                     text,
                     setText
                   }: SearchBarProps) {

  return (
    <div style={styleSearchBar ? styleSearchBar : {
      width: "70%",
      minWidth: "300px",
      height: "40px",
      marginTop: "20px",
    }}>
      <PageSearchBar>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            {icon}
          </InputLeftElement>
          <Input
            type='text'
            placeholder={placeholder}
            value={text}
            onChange={e => setText ? setText(e.target.value) : null}
            color="#918D86"
            borderColor={"#918D86"}
            fontFamily={"Mabry Pro"}
            _hover={{
              borderColor: "#918D86",
              backgroundColor: "#F5F3ED"
            }}
            _placeholder={{
              color: "#918D86"
            }}
          />
        </InputGroup>
      </PageSearchBar>
    </div>
  )
}

export default SearchBar;